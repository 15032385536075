<template>
  <OwnerTabs />
</template>

<script>
import OwnerTabs from "@/components/owner/OwnerTabs";

export default {
  components: { OwnerTabs },
};
</script>

<style lang="scss" scoped>
.addTrialIcon {
  position: absolute;
  top: 25px;
  right: 60px;
  color: $primary;
  font-size: 1.5em;
  z-index: 99;

  &:hover {
    cursor: pointer;
    color: $secondary;
  }
}
</style>
