<template>
  <el-tabs v-model="activeName" @tab-click="handleClick">
    <div class="dog-top-actions">
      <el-tooltip effect="dark" content="Add Trial" placement="left">
        <font-awesome-icon
          :icon="['fa', 'plus-circle']"
          class="addTrialIcon"
          @Click="handleAddTrial"
        ></font-awesome-icon>
      </el-tooltip>
    </div>
    <el-tab-pane label="Upcoming Trials" :lazy="true" name="upcomingTrials">
      <base-spinner v-if="isLoading"></base-spinner>
      <div v-else-if="!isLoading && upcomingTrials.length > 0">
        <OwnerTrialList
          v-for="trial in upcomingTrials"
          :key="trial._id"
          :trial="trial"
          upcomingOrPast="upcoming"
        />
      </div>
      <h3 v-else>No upcoming trials</h3>
    </el-tab-pane>
    <el-tab-pane label="Past Trials" :lazy="true" name="pastTrials">
      <base-spinner v-if="isLoading"></base-spinner>
      <div v-else-if="!isLoading && pastTrials.length > 0">
        <OwnerTrialList
          v-for="trial in pastTrials"
          :key="trial._id"
          :trial="trial"
          upcomingOrPast="past"
        />
      </div>
      <h3 v-else>No past trials</h3>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import OwnerTrialList from "./OwnerTrialList.vue";

export default {
  components: { OwnerTrialList },
  data() {
    return {
      isLoading: true,
      activeName: "upcomingTrials",
    };
  },
  computed: {
    hasUserId() {
      return this.$store.getters["user/getUserId"];
    },
    upcomingTrials() {
      return this.$store.getters["trial/ownerUpcomingTrials"];
    },
    pastTrials() {
      return this.$store.getters["trial/ownerPastTrials"];
    },
  },
  watch: {
    hasUserId() {
      this.loadTrials(this.activeName);
    },
  },
  methods: {
    handleClick(tab) {
      this.loadTrials(tab.props.name);
    },
    async loadTrials(whichList) {
      this.isLoading = true;
      try {
        await this.$store.dispatch("trial/loadTrials", whichList);
      } catch (error) {
        console.error(error);
        this.$message({
          showClose: true,
          message: "Error getting trials",
          type: "error",
        });
      }
      this.isLoading = false;
    },
    handleAddTrial() {
      this.$router.push({ name: "addtrial", params: { addOrEdit: "add" } });
    },
  },
  created() {
    this.hasUserId && this.loadTrials("upcomingTrials");
  },
};
</script>
