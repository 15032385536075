<template>
  <div class="wrapper">
    <section class="sanction-date" @click="classRegistration">
      <p class="primary-bold">{{ trial.sanctionBody }}</p>
      <p>{{ getDate(trial.startDate) }} - {{ getDate(trial.endDate) }}</p>
    </section>

    <section class="event-type" @click="classRegistration">
      <div>
        <p>{{ trial.eventType }}</p>
      </div>
    </section>

    <section class="trial-location" @click="classRegistration">
      <p>{{ trial.trialName }}</p>
      <p>{{ trial.city }}, {{ trial.state }}</p>
    </section>

    <section class="registered-dogs">
      <div
        v-for="dog in ownerDogs"
        :key="dog._id"
        :class="isRegistered(dog)"
        @Click="handleRegisterDog(dog)"
      >
        <el-tooltip effect="dark" :content="dog.callName" placement="top">
          <div>
            <DogAvatar :dog="dog" avatarSize="35" @imageLoaded="imageLoaded" />
          </div>
        </el-tooltip>
      </div>
    </section>

    <!-- <section class="trial-status" @click="classRegistration">
      <div>
        <p>{{ trial.trialStatus }}</p>
      </div>
    </section> -->
    <div class="remove-trial">
      <el-tooltip effect="dark" content="Remove Trial" placement="top">
        <font-awesome-icon
          :icon="['fa', 'minus-circle']"
          class="remove-trial-icon"
          @Click="handleRemoveTrial"
        ></font-awesome-icon>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import DogAvatar from "@/ui/DogAvatar";

export default {
  props: { trial: Object, upcomingOrPast: String },
  components: { DogAvatar },
  data() {
    return {
      isLoading: true,
    };
  },
  computed: {
    ownerDogs() {
      return this.$store.getters["user/getUserProfile"].dogs;
    },
    getToken() {
      return this.$store.getters["user/getUserTokens"].idToken;
    },
  },
  methods: {
    getDate(theDate) {
      return moment(theDate).format("MMM D");
    },
    imageLoaded() {
      this.isLoading = true;
    },
    isRegistered(dog) {
      return this.trial.trialRegistrations.find((dogId) => dogId === dog._id)
        ? "registered"
        : "not-registered";
    },
    async handleRegisterDog(dog) {
      const isRegistered = this.isRegistered(dog);

      isRegistered === "registered"
        ? this.removeRegistration(dog)
        : this.registerDog(dog);
    },
    async registerDog(dog) {
      try {
        this.$store.dispatch("trial/registerForTrial", {
          trialId: this.trial._id,
          dogId: dog._id,
          upcomingOrPast: this.upcomingOrPast,
        });

        this.$message({
          showClose: true,
          message: `${dog.callName} registered for ${this.trial.trialName}`,
          type: "success",
          duration: 5000,
        });
      } catch (error) {
        console.log(error);
        this.$message({
          showClose: true,
          message: "Error OTL1000 registering dog for trial",
          type: "error",
          duration: 5000,
        });
      }
    },
    async removeRegistration(dog) {
      this.$confirm(
        `Are you sure you want to remove ${dog.callName} from ${this.trial.trialName} which includs removal of all classes and results?`,
        "Warning",
        {
          confirmButtonText: "Yes",
          cancelButtonText: "No",
          type: "warning",
        }
      )
        .then(() => {
          try {
            this.$store.dispatch("trial/removeDogFromTrial", {
              dogId: dog._id,
              trialId: this.trial._id,
              upcomingOrPast: this.upcomingOrPast,
            });
            this.$message({
              showClose: true,
              message: `${dog.callName} removed from ${this.trial.trialName}`,
            });
          } catch (error) {
            console.log(error);
            this.$message({
              showClose: true,
              message: "Error OTL1100 removing dog from trial",
              type: "error",
              duration: 5000,
            });
          }
        })
        .catch(() =>
          this.$message({
            type: "info",
            message: "Remove Cancelled",
          })
        );
    },
    classRegistration() {
      this.$router.push({
        name: "classregistration",
        params: { trialId: this.trial._id },
      });
    },
    handleRemoveTrial() {
      if (this.trial.trialRegistrations.length > 0) {
        this.$message({
          showClose: true,
          message:
            "Please remove all dog registratons before removing the trial",
          type: "error",
          duration: 5000,
        });
      } else if (this.trial.trialRegistrations.length === 0) {
        this.$confirm(
          `Are you sure you want to remove ${this.trial.trialName}?`,
          "Warning",
          {
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            type: "warning",
          }
        )
          .then(async () => {
            try {
              await this.$store.dispatch("trial/updateTrial", {
                trialId: this.trial._id,
                update: { isDeleted: true },
              });

              await this.$store.dispatch(
                "trial/removeTrialFromStore",
                this.trial
              );

              this.$message({
                showClose: true,
                message: `${this.trial.trialName} removed`,
                type: "error",
                duration: 5000,
              });
            } catch (error) {
              console.log(error);
              this.$message({
                showClose: true,
                message: "Error TOL1100 removing trial",
                type: "error",
                duration: 5000,
              });
            }
          })
          .catch(() =>
            this.$message({
              type: "info",
              message: "Remove Cancelled",
            })
          );
      } else {
        console.log("Error: Registrations not correct");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 5px;
  max-width: 1110px;

  &:hover {
    cursor: pointer;
  }
}

section {
  padding: 6px 10px;
  margin-right: 5px;
  background: #fff;
  border: 1px solid $tertiary-25;

  p {
    padding: 0;
    margin: 3px 0;
    color: $tertiary;
  }
}

.sanction-date {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  align-content: center;
  width: 13%;
  border-left: 10px solid $primary;
  border-radius: 5px 0 0 5px;
}

.event-type {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 24%;
}

.registered-dogs {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 26%;

  .registered {
    opacity: 1;
  }

  .not-registered {
    opacity: 0.4;
  }

  div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 35px;
    margin: 0 5px;
  }

  div:hover {
    cursor: pointer;
    opacity: 1;
  }
}

.trial-location {
  width: 19%;
  text-align: center;
}

.trial-status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
  text-align: center;
  border-radius: 0 5px 5px 0;
}

.primary-bold {
  font-weight: bold;
  color: $primary;
}

.remove-trial {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  .remove-trial-icon {
    color: $tertiary-75;
  }

  .remove-trial-icon:hover {
    color: $secondary;
  }
}
</style>
